export default {
  ArcaneArmor: 'РЕАКЦИЯ: Во время хода врага, поглотить урон от первой атаки воинов силой 3 и более по вашему воину или герою',
  CursedChackle: 'Накладывается на вражеского воина. После того как этот воин нанес урон в свой ход, нанести такой же урон вражескому герою заклинанием',
  InvisibilityCloak: 'Накладывается на союзного воина. Он получает НЕВИДИМОСТЬ',
  SoulDrain: 'Уничтожить вражеского воина на поле боя',
  Teleport: 'Переместить союзного воина на любую свободную клетку на поле боя',
  TownPortal: 'Вражеский воин, а также наложенные на него заклинания, отправляются в руки к владельцам в изначальном состоянии',
  Transformation: 'Вражеский воин получает ОГЛУШЕНИЕ и вы можете передвинуть его на соседнюю клетку',
  MagicMist: 'Накладывается на любого воина, он получает НЕВИДИМОСТЬ',
  MassPowerDrain: 'Атакует силой 2 всех вражеских воинов на поле и вылечивает 2 здоровья у всех ваших вионов',
  SinisterFog: 'Кладется рядом с полем. Вражеские воины которые не имеют союзников в прилегающих клетках получают -2 к атаке и ответному удару',
};
