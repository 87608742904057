/* eslint-disable max-len */
export default {
  step0: 'Добро пожаловать в обучающий режим! Здесь вы изучите основы игры Duel of Warlords',
  step1: 'Цель сражения - победить вражеского героя, для этого его здоровье должно опуститься до нуля, текущее здоровье героя указано на карте героя в иконке красного щита',
  step2: 'В процессе игры вы будете использовать карты воинов, заклинаний и способности вашего героя. Сначала рассмотрим структуру поля боя (нажмите "Продолжить")',
  step3: 'Если вы играете за игрока №1, ваш герой и воины располагаются на левой половине поля боя, а если за игрока №2 - то воины и герой справа',
  step4: 'В обучении вы будете играть за игрока №1, поэтому с правой стороны будет ваш противник',
  step5: 'Все поле боя условно делится на линии - клетки по вертикали и шеренги -  клетки по горизонтали. В одной шеренге могут быть воины обоих игроков, а в одной линии - только воины одного игрока',
  step6: 'Рядом с полем могут помещаться карты заклинаний, которые действуют на шеренгу, линию или на все поле боя, в зависимости от их расположения',
  step7: 'Попробуем провести первый бой: у вас и у вашего противника появились воины на поле боя',
  step8: 'По умолчанию воины в свой ход могут либо атаковать, либо передвигаться по полю боя на любую свободную клетку',
  step9: 'Для того, чтобы посмотреть описания воина, а также совершить им атаку или передвижение, нужно выделить его нажатием на карту. Выделите любого воина, а потом нажмите на описание уже выделенной карты. После нажмите "Продолжить"',
  step10: 'Если ваш воин имеет желтую рамку вокруг и сейчас ваш ход, значит этот воин активен, и вы можете сходить им. По умолчанию воины атакуют только врагов, находящихся в их шеренге. Выделите еще раз вашего "Воина света" и атакуйте им вражеского голема',
  step11: 'Отлично! Вы атаковали вражеского голема силой атаки 2 - сила атаки воина указана в верхнем левом углу карты. Так как голем остался жив (здоровье указано в иконке "щита" на карте воина), он совершил ответный удар по вашему рыцарю также силой 2 (сила ответного удара указана в черной иконке)',
  step12: 'Если в шеренге нет вражеских воинов, то воин может атаковать героя - герой доступен для атаки из любой шеренги. Выделите вашего Гриффона и нажмите на соседнюю клетку, чтобы переместить его и не дать атаковать вашего героя',
  step13: 'После совершения атаки или передвижения воины становятся неактивными и не могут атаковать или передвигаться в этом ходу. Теперь нажмите "Продолжить", чтобы посмотреть ход врага',
  step14: 'Итак, Гриффон принял удар на себя, при этом от ответного удара здоровье гаргульи опустилось до нуля, и она покинула поле боя, отправившись в сток, а "Земляной голем" убил вашего "Воина света"',
  step15: 'Последняя попавшая в сток карта всегда лежит сверху колоды. Щелкните на Гаргулью, чтобы посмотреть сток оппонента, а потом закройте окно стока и нажмите "Продолжить": время узнать об очках действия, которые нужны для разыгрывания карт из руки',
  step16: 'В начале игры каждый игрок имеет по 1 очку действия. В синем кружке рядом с героем отображаются ваши очки действия: "оставшиеся очки" / "начальные очки" в этом раунде',
  step17: 'После того, как игрок закончил ход, его очки действия восстанавливаются и увеличиваются на 1 единицу (максимум до 10). Теперь изучим основные осбенности атак воинов - нажмите "Продолжить"',
  step18: 'Итак, к вам в руку пришла карта "Имперский стрелок", его стоимость 1, она отображается в синем круге на карте. Выделите карту воина и нажмите на описание выделенной карты, чтобы посмотреть описание токена "СТРЕЛЬБА"',
  step19: 'Вы можете класть воинов в любую клетку на вашей половине поля, но стрелков лучше класть дальше от клеток врага так как они, как правило, имееют низкое здоровье и плохо защищаются. Выделите карту "Имперский стрелок" и поместите ее на поле боя',
  step20: 'Воины входят в игру в неактивном состоянии, поэтому вы не сможете ими сразу сходить. У вас не осталось очков действия, поэтому нажмите красную кнопку "закончить ход" в верхней плашке, чтобы начать следующий раунд',
  step21: 'Начался 2 раунд, и у вас 2 очка действия. Также каждый ход вы получаете одну карту из своей колоды: сейчас это "Воин света"',
  step22: 'Выделите "Воина света" и поместите его на поле боя перед вашим стрелком, чтобы защитить его от будущих атак',
  step23: 'Отлично! У вас закончились очки действия, поэтому нажмите "закончить ход", чтобы начать следующий ход и получить новую карту',
  step24: 'Теперь у вас в руке "Имперский гриффон". Выделите Гриффона и посмотрите описание токена "ПОЛЕТ", а потом поместите воина на поле боя на любую клетку',
  step25: 'Теперь, когда все воины на поле, попробуем совершить атаку вашим стрелком: как вы уже знаете, стрелки атакуют любого воина в шеренге, при этом они не получают ответный удар от атакуемого воина',
  step26: 'Выделите вашего "Имперского стрелка" и атакуйте любого доступного воина',
  step27: 'Отлично! Теперь попробуем совершить атаку вашим Воином света. Он может атаковать только ближайшего к нему воина в шеренге, поэтому ваш Воин света не сможет атаковать Элемента льда, пока жив Земляной голем',
  step28: 'Выделите Воина света и совершите им атаку',
  step29: 'Хорошо, теперь выделите вашего Гриффона. Так как в шеренге с Гриффоном нет вражеских воинов, просто атакуйте им вражеского героя',
  step30: 'Все ваши воины на поле боя сходили и стали неактивными. Время нажать "закончить ход"',
  step31: 'К вам пришла карта мгновенного заклинания - значок молнии в верхнем левом углу карты. Такие карты имеют единоразовый эффект и после использования отправляются в сток. Значок "вихря" на карте обозначает, что она принадлежит к магии воздуха',
  step32: 'Удар молнии - это атакующее заклинание (это понятно из описания карты). Такие заклинания обычно действуют только на воинов, для атаки героя вы должны использовать ваших воинов',
  step33: 'Выберите ваше заклинание и добейте вражеского Земляного голема',
  step34: 'Хорошо! Сейчас у вас остались очки действия, но нет карт в руке - самое время применить стандартную способность героя',
  step35: 'Стандартная способность героя - "Прилив сил", она позволяет потратить 1 очко действия и взять карту из колоды в руку - эта способность есть у каждого героя. После применения способности герой становится неактивным. Выделите героя и нажмите на кнопку "ВЫТЯНУТЬ КАРТУ"!',
  step36: 'К вам пришла карта временного заклинания - она имеет значок часов в левом верхнем углу, такие карты действуют один ход и перед началом вашего следующего хода отправляются в сток',
  step37: 'Посмотрите описание карты, выделив ее, а после разыгарйте карту, поместив в шеренгу рядом с Гриффоном',
  step38: 'Вы сделали все действия в этом ходу, поэтому время нажать "закончить ход"',
  step39: 'Враг разыграл карту "Огненного голема" в свой ход и получил повреждение от святой земли (изначальное здоровье Огненного голема равно 6)',
  step40: 'Карта "Святая земля" отправилась в сток, как временное заклинание',
  step41: 'К вам в руку пришла карта заклинания "Тяжелая броня" - это постоянная карта, потому что она имеет знак "бесконечность" в верхнем левом углу, такая карта накладывается на воина и будет действовать, пока этот воин не покинет поле боя',
  step42: 'Выберите заклинание "Тяжелая броня" и примените его к вашему "Воину света", чтобы защитить его от атак других воинов',
  step43: 'Хорошо, у вас еще остались очки действия, и вы можете использовать уникальную способность вашего героя "Удар молотом": атаковать воина силой 1 и переместить его на соседнюю клетку. Выделите вашего героя и атакуйте вражеского Огненного голема',
  step44: 'Теперь передвиньте голема на соседнюю клетку',
  step45: 'Отлично, теперь время нажать "закончить ход"',
  step46: 'Начался новый ход, и у вас в руке еще одно мгновенное заклинание "Удар грома"',
  step47: '"Удар грома" не только атакует целевого воина, но и оглушает воинов в соседних клетках. Соседние - это все клетки вокруг цели, кроме клеток наискосок (в том числе это могут быть вражеские клетки)',
  step48: 'Попробуйте применить заклинание по вражескому Земляному голему - вы увидите, как сработает ваш "Удар грома"',
  step49: 'Вы смогли убить голема, но, как видите, вы оглушили не только вражеского воина, но и своего - с такими заклинаниями надо быть аккуратнее. Оглушенные воины поворачиваются на 90 градусов и пропускают следующий ход, но они все еще могут наносить ответные удары',
  step50: 'Теперь нажмите "закончить ход", чтобы посмотреть еще одну разновидность заклинаний в игре',
  step51: 'Заклинание "Последний шанс" имеет эффект "РЕАКЦИЯ" - такое заклинание применяется в ответ на какое-то событие во время хода врага. При разыгровке карты вы не тратите очки действия, и враг не видит, что вы разыграли такую карту!',
  step52: 'Если событие произойдет, то карта сработает, и у вас отнимутся очки действия, а если нет, то карта просто отправится в сток в начале вашего хода.',
  step53: 'Выделите карту "Последний шанс" и разыграйте ее',
  step54: 'Отлично! Время закончить ход и посмотреть, сработает ли ваше заклинание',
  step55: '"Ученик мага" оппонента атаковал вашего Имперского стрелка, но не смог его убить, так как сработало заклинание Последний шанс. После использования заклинание сразу отправилось в сток',
  step56: 'Еще один важный момент, касающийся заклинаний: во время обучения вы использовали заклинания 2-х школ магии, потому что ваш герой может использовать магию только этих школ, что обозначено соответствующими иконками на карте героя. Эти ограничения нужно учитывать при сборке колод',
  step57: 'Сейчас все ваши воины и герой стали активными, кроме оглушенного ранее воина - он повернулся назад на 90 градусов и стал просто неактивным. В обычной игре неактивные воины и герой становятся активными в начале хода игрока, а оглушенные - неактивными',
  step58: 'Итак вы изучили основные положения игры Duel of Warlords, нажмите "продолжить" чтобы завершить обучение!',
  step59: '',
};
